.container {

    width: 100%
}

@media (min-width: 640px) {

    .container {

        max-width: 640px
    }
}

@media (min-width: 768px) {

    .container {

        max-width: 768px
    }
}

@media (min-width: 1024px) {

    .container {

        max-width: 1024px
    }
}

@media (min-width: 1280px) {

    .container {

        max-width: 1280px
    }
}

@media (min-width: 1536px) {

    .container {

        max-width: 1536px
    }
}

    .creation-form-container {

    --tw-bg-opacity: 1;

    background-color: rgb(255 241 193 / var(--tw-bg-opacity))
}

    .creation-form-container .control {

    gap: 0.25rem
}
    .creation-form-container .control label {

    font-size: 10px;

    font-weight: 400
}
    .creation-form-container .control input,
    .creation-form-container .control .input,
    .creation-form-container .control textarea {

    border-style: none;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
