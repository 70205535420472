.container {
        width: 100%;
}
@media (min-width: 640px) {
        .container {
                max-width: 640px;
        }
}
@media (min-width: 768px) {
        .container {
                max-width: 768px;
        }
}
@media (min-width: 1024px) {
        .container {
                max-width: 1024px;
        }
}
@media (min-width: 1280px) {
        .container {
                max-width: 1280px;
        }
}
@media (min-width: 1536px) {
        .container {
                max-width: 1536px;
        }
}
    .dropdown {
        background-color: var(--white);
        box-shadow: var(--box-shadow);
        z-index: 99999;
    }
    .dropdown-arrow {
        z-index: -1;
    }
    .dropdown-arrow::after {
        content: "";
        width: 6px;
        height: 6px;
        transform: rotate(-45deg);
        background: var(--white);
        box-shadow: var(--box-shadow);
        position: absolute;
        z-index: -1;
        top: -3px;
        left: calc(50% - 10px);
    }
    .dropdown-content {
        background: var(--white);
    }